import styled from 'styled-components';
import Img from 'gatsby-image';

export default styled(Img)`
  position: absolute !important;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;
