import React from 'react';
import styled from 'styled-components';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { Section as BaseSection } from '../../components';
import { sizes } from '../../styles/helpers/media';
import { colors, offsets } from '../../styles';
import Contacts from './contacts';

const GEO_POSITION = [58.04589, 56.086797];

const Section = styled(BaseSection)`
  box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.2);
  padding-bottom: 0 !important;

  & ymaps {
    filter: grayscale(12%);
  }
`;

export default () => (
  <Section id="contacts">
    <YMaps>
      <Map
        width="100%"
        height="800px"
        defaultState={{ center: GEO_POSITION, zoom: 17 }}
        instanceRef={ref => {
          ref && ref.behaviors.disable('scrollZoom');
        }}
      >
        <Contacts />

        <Placemark
          geometry={{
            type: 'Point',
            coordinates: GEO_POSITION,
          }}
          options={{
            iconColor: colors.primary,
          }}
        />
      </Map>
    </YMaps>
  </Section>
);
