import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from '../styles/helpers';
import { offsets, fontSizes, transitions, colors } from '../styles';
import { Jumbotron } from '../components';

const Title = styled.h1`
  font-size: 36px;
  margin: 0 auto ${offsets.xs};
  font-weight: 300;
  text-transform: uppercase;

  ${media.sm`
    font-size: 48px;
    margin-bottom: ${offsets.sm};
  `}

  ${media.md`
    font-size: 60px;
  `}
`;

const Caption = styled.p`
  font-size: ${fontSizes.sm};
  margin: 0;
  text-transform: lowercase;

  ${media.sm`
  font-size: ${fontSizes.md};
  `}

  ${media.md`
    font-size: ${fontSizes.xlg};
  `}
`;

const CTA = styled(Link)`
  transition: color ${transitions.base}, transform ${transitions.base};
  text-align: center;
  cursor: pointer;

  &:hover {
    color: ${colors.primary};
  }

  &:active {
    transform: scale(0.99);
  }
`;

export default () => (
  <Jumbotron fullheight>
    <CTA to="/order">
      <Title>Голоден?</Title>
      <Caption>Жми, не стесняйся!</Caption>
    </CTA>
  </Jumbotron>
);
