import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Section } from '..';
import PrimaryBg from './background-primary';
import SecondaryBg from './background-secondary';
import { media } from '../../styles/helpers';
import { fontSizes, offsets, colors, transitions } from '../../styles';

const withSectionHeightStyles = ({ fullheight }) => {
  if (fullheight) {
    return css`
      height: 100vh;

      ${media.md`
        height: calc(100vh - 88px);
      `}
    `;
  }

  return css`
    padding: ${offsets.xl3} 0 !important;
  `;
};

const JumboSection = styled(Section).attrs({
  id: 'jumbotron',
  withShadow: true,
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;
  ${withSectionHeightStyles}
`;

const appearanceToBgMapping = {
  primary: <PrimaryBg />,
  secondary: <SecondaryBg />,
};

const Jumbotron = ({ fullheight, appearance, children }) => (
  <JumboSection fullheight={fullheight}>
    {appearanceToBgMapping[appearance]}
    {children}
  </JumboSection>
);

Jumbotron.propTypes = {
  fullheight: PropTypes.bool,
  appearance: PropTypes.oneOf(['primary', 'secondary']),
};

Jumbotron.defaultProps = {
  fullheight: false,
  appearance: 'primary',
};

export default Jumbotron;
