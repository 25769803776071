import React from 'react';
import styled from 'styled-components';
import { Section, Container } from '../../components';
import { Title } from '../../components/ui';
import ChiefLogo from './pics/chief';
import DeliveryLogo from './pics/delivery';
import ChoiceLogo from './pics/choice';
import Advantage from './advantage';
import { media } from '../../styles/helpers';
import { fontSizes, offsets, colors, transitions } from '../../styles';

const Advantages = styled(Section).attrs({
  id: 'advantages',
  withShadow: true,
})`
  background-color: #fff;
`;

const List = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export default () => (
  <Advantages id="advantages">
    <Container column>
      <Title as="h2" size="lg">
        Почему мы
      </Title>
      <List>
        <Advantage
          Logo={ChiefLogo}
          title="Открытая кухня"
          descr="Вы можете видеть, как готовится ваше&nbsp;блюдо"
        />
         <Advantage
          Logo={DeliveryLogo}
          title="Доставка"
          descr=""
          extra='Доставка осуществляется от любой суммы<br/>за счет покупателя на Яндекс.Такси.<br/><br/>Сделать заказ также можно в приложении "Яндекс.Еда"'
        />
        <Advantage
          Logo={ChoiceLogo}
          title="Цена = качество"
          descr="Диаметр пиццы 33 см, тонкое тесто, много начинки"
        />
      </List>
    </Container>
  </Advantages>
);
