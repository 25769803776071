import React from 'react';

export default () => (
  <svg width="48" height="48" viewBox="0 0 48 48">
    <path d="M0 0h48v48h-48z" fill="none" />
    <path
      fill="#ffd340"
      d="M24 4c-7.13 0-13.55 3.09-17.98 8l17.98 32 17.99-31.99c-4.43-4.92-10.85-8.01-17.99-8.01zm-10 10c0-2.21 1.79-4 4-4s4 1.79 4 4-1.79 4-4 4-4-1.79-4-4zm10 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"
    />
  </svg>
);
