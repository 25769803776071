import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Section, Container } from '../../components';
import { Title } from '../../components/ui';
import ChiefLogo from './pics/chief';
import { media } from '../../styles/helpers';
import { fontSizes, offsets, colors, transitions } from '../../styles';

const Shape = styled.li`
  width: 100%;
  margin-bottom: ${offsets.xl};
  text-align: center;

  & > * {
    max-width: 82%;
    margin: auto;
  }

  ${media.md`
    width: 33%;
    margin-bottom: ${offsets.md};
  `}
`;

const ItemTitle = styled(Title)`
  font-weight: bold;
  margin: ${offsets.xs} auto !important;
`;
const LogoWrapper = styled.div`
  & svg {
    width: 80%;
    height: 100px;
  }
`;

const Extra = styled.div`
  font-size: 12px;
  margin-top: 8px;
`;

const Advantage = ({ Logo, title, descr, extra }) => (
  <Shape>
    <LogoWrapper>
      <Logo />
    </LogoWrapper>
    <ItemTitle as="h3" size="sm">
      {title}
    </ItemTitle>
    <div dangerouslySetInnerHTML={{__html: descr}}/>
    {extra && <Extra dangerouslySetInnerHTML={{__html: extra}}></Extra>}
  </Shape>
);

Advantage.propTypes = {
  Logo: PropTypes.node,
  title: PropTypes.string,
  descr: PropTypes.string,
  extra: PropTypes.string,
};

export default Advantage;
