import React from 'react';
import styled from 'styled-components';
import { Section, Container } from '../../components';
import { Title } from '../../components/ui';
import { media } from '../../styles/helpers';
import { fontSizes, offsets, colors, transitions } from '../../styles';
import goodHoursPic from '../../images/action-good-hours.jpg';
import action3Pizza from '../../images/action-3pizza.png';


const SalesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -${offsets.sm};

  ${media.md`
     margin: auto;
     width: 70%;
  `}
`;

const SaleShape = styled.div`
  flex-basis: 100%;
  padding: ${offsets.sm};
  height: 50vh;
  min-height: 330px;
  border-radius: 4px;

  ${media.md`
    flex-basis: 50%;
  `}
`;

const SaleMock = styled.div`
  width: 100%;
  height: 100%;
  background-position: center center;
  border-radius: 5px;

  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
`;

const GoodHours = styled(SaleMock)`
  background-image: url(${goodHoursPic});
`;

const Pizza3Action = styled(SaleMock)`
background-image: url(${action3Pizza});
`;

export default () => (
  <Section id="sale">
    <Container column>
      <Title as="h2" size="lg">
        Акции
      </Title>
      <SalesContainer>
        <SaleShape>
          <Pizza3Action/>
        </SaleShape>
        {/* <SaleShape>
          <GoodHours />
        </SaleShape> */}
      </SalesContainer>
    </Container>
  </Section>
);
