import styled from 'styled-components';
import { media } from '../styles/helpers';
import { offsets } from '../styles';

export default styled.section`
  position: relative;
  display: flex;
  padding-bottom: ${offsets.lg};
  ${({ withShadow }) =>
    withShadow && '  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);'}

  ${media.md`
    padding-bottom: ${offsets.xl};
  `}
`;
