import React from 'react';

export default () => (
  <svg
    width="256.74667mm"
    height="401.24445mm"
    viewBox="0 0 909.73231 1421.7323"
    id="svg4144"
  >
    <g id="layer1" transform="translate(74.866142,212.78964)">
      <g transform="matrix(1,0,0,-1,-4,1138.0765)" id="g3003">
        <path d="m 464,128 q 0,33 -23.5,56.5 Q 417,208 384,208 351,208 327.5,184.5 304,161 304,128 304,95 327.5,71.5 351,48 384,48 417,48 440.5,71.5 464,95 464,128 Z m 208,160 0,704 q 0,13 -9.5,22.5 -9.5,9.5 -22.5,9.5 l -512,0 q -13,0 -22.5,-9.5 Q 96,1005 96,992 l 0,-704 q 0,-13 9.5,-22.5 Q 115,256 128,256 l 512,0 q 13,0 22.5,9.5 9.5,9.5 9.5,22.5 z m -192,848 q 0,16 -16,16 l -160,0 q -16,0 -16,-16 0,-16 16,-16 l 160,0 q 16,0 16,16 z m 288,16 0,-1024 Q 768,76 730,38 692,0 640,0 L 128,0 Q 76,0 38,38 0,76 0,128 l 0,1024 q 0,52 38,90 38,38 90,38 l 512,0 q 52,0 90,-38 38,-38 38,-90 z" />
      </g>
    </g>
  </svg>
);
