import React from 'react';
import styled from 'styled-components';
import { Title as BaseTitle } from '../../components/ui';
import LogoVK from './icons/vk';
import LogoViber from './icons/viber';
import LogoMobile from './icons/mobile';
import LogoGeoPoint from './icons/geopoint';
import IconClock from './icons/clock';
import { media } from '../../styles/helpers';
import { offsets, colors } from '../../styles';
import { contacts } from '../../data';

const Shape = styled.div`
  position: absolute;
  max-width: 340px;
  background: #fff;
  z-index: 9;
  padding: ${offsets.md};
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
  margin: auto;
  top: ${offsets.xl2};
  left: 0;
  right: 0;

  ${media.sm`
    right: 0;
    left: auto;
    right: ${offsets.xl2};
  `}
`;

const Title = styled(BaseTitle)`
  margin: 0 auto ${offsets.md} !important;
`;

const ContactsItem = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: ${offsets.sm};
  }

  & svg {
    width: ${offsets.md};
    height: ${offsets.md};
    margin-right: ${offsets.xs};
  }
`;

const Link = styled(ContactsItem).attrs({ as: 'a' })`
  margin-bottom: ${offsets.md};

  &:last-child {
    padding-bottom: ${offsets.sm};
    border-bottom: 1px solid #e9e9e9;
  }

  &:hover {
    color: ${colors.primary};
  }
`;

export default () => (
  <Shape>
    <div>
      <Title>Контакты</Title>
      <Link href="https://vk.com/mamapizza_perm" rel="nofollow" target="_blank">
        <LogoVK />
        https://vk.com/mamapizza_perm
      </Link>
      <Link href="viber://chat?number=+79082697714" rel="nofollow">
        <LogoViber />
        +7 (951) 930-43-19
      </Link>
      <Link href="tel:+73422883880" rel="nofollow">
        <LogoMobile />
        +7 (342) 288-38-80
      </Link>
    </div>
    <div>
      <ContactsItem>
        <LogoGeoPoint />
        {contacts.address}
      </ContactsItem>
      <ContactsItem>
        <IconClock style={{ height: '20px' }} />
        <div>
          <b>Время работы:</b>
          <br />
          пн-ср: 11:00 - 21:00
          <br />
          чт-вс: 11:00 - 22:00
        </div>
      </ContactsItem>
    </div>
  </Shape>
);
