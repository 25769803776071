import React from 'react';
import { Layout } from '../components';
import { Sales, Advantages, About, Contacts } from '../containers';
import Jumbotron from '../containers/jumbotron-main-page';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Мама Food" keywords={[`Пицца`, `доставка`, `бургеры`, `роллы`]} />
    <Jumbotron fullheight />
    <Sales />
    <Advantages />
    <About />
    <Contacts />
  </Layout>
);

export default IndexPage;
