import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from '../../components/background-image';

const query = graphql`
  query {
    fileName: file(relativePath: { eq: "team-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2240, cropFocus: CENTER, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const AboutUsBg = styled(Img)`
  & img {
    object-position: 0 15%;
  }
`;

export default () => (
  <StaticQuery
    query={query}
    render={({ fileName: { childImageSharp } }) => (
      <AboutUsBg {...childImageSharp} />
    )}
  />
);
