import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from '../background-image';

const query = graphql`
  query {
    fileName: file(relativePath: { eq: "jumbotron-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2240, cropFocus: CENTER, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

export default () => (
  <StaticQuery
    query={query}
    render={({ fileName: { childImageSharp } }) => <Img {...childImageSharp} />}
  />
);
