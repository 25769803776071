import React from 'react';
import styled from 'styled-components';
import Slider from 'react-styled-carousel';
import { Section as BaseSection, Container } from '../../components';
import Background from './background';
import { Title } from '../../components/ui';
import { media } from '../../styles/helpers';
import { fontSizes } from '../../styles';

const Text = styled.div`
  width: 100%;
  margin: auto;
  text-align: left;
  line-height: 1.9;
  font-size: ${fontSizes.sm};

  ${media.sm`
    text-align: center;
    width: 80%; 
    font-size: ${fontSizes.md};
  `}

  ${media.md`
    width: 70%;
    font-size: ${fontSizes.lg};
  `}
`;

const Section = styled(BaseSection)`
  ${media.sm`
    min-height: 390px;
  `}

  ${media.md`
    min-height: 520px;
  `}
`;

export default () => (
  <Section id="about">
    <Background />
    <Container column>
      <Title as="h2" size="lg" style={{ marginBottom: 0 }}>
        О нас
      </Title>

      <Text>
        Мы - это небольшая семейная пиццерия.
        <br /> Мы - это особое меню, учитывающее сочетание ваших любимых вкусов.{' '}
        <br />
        Мы - это забота о каждом клиенте. В отличие от сетевых пиццерий, мы
        работаем не на количество, а на качество. Нам важно, чтобы Вам было
        вкусно!
      </Text>
    </Container>
  </Section>
);
